import { NamedScrollPosition } from './types'

/**
 * We've got a few incoming arrays which may all be different lengths. We want
 * to grow them all to be the same length by duplicating the last element as
 * many times as necessary.
 * For example,
 *     extendToLargestArity(
 *       [1, 2],
 *       [3, 4, 5, 6],
 *       [7, 8, 9]
 *     )
 * will return
 *     [
 *       [1, 2, 2, 2],
 *       [3, 4, 5, 6],
 *       [7, 8, 9, 9]
 *     ]
 */
export function extendToLargestArity<T extends unknown[][]>(...arrays: T): T {
  // Figure out the longest arity
  const targetArity = Math.max(...arrays.map(arr => arr.length))

  return arrays.map(arr => {
    // Already the correct length
    if (arr.length === targetArity) {
      return arr
    }

    // Create an array enough to "top up" the original array, and initialise its
    // values with the same value as the last item in the original array
    const extendedArr = Array.from(
      { length: targetArity - arr.length },
      () => arr[arr.length - 1]
    )

    // The result is the combination of those two arrays
    return [...arr, ...extendedArr]
  }) as T
}

/**
 * Walk backwards through an array representing responsive values to find a
 * concrete value for the given index.
 */
export function getValueFromResponsiveArray<T = any>(
  values: T[],
  index: number
): T | undefined {
  let stopIndex = index

  while (stopIndex >= 0) {
    const value = values[stopIndex]

    if (value != null) {
      return value
    }
    stopIndex -= 1
  }

  return undefined
}

export function calculateScrollDistance(
  reelRef: React.RefObject<HTMLElement> | null,
  direction: 'prev' | 'next'
): number {
  const childWidth = reelRef?.current?.children[0]?.clientWidth || 0
  const elementViewportWidth = reelRef?.current?.clientWidth || 0
  const numberOfItemsOnScreenCompletely =
    (elementViewportWidth / childWidth) >> 0
  const scrollAmount = numberOfItemsOnScreenCompletely * childWidth
  let maxScroll = 0
  if (direction === 'next') {
    maxScroll =
      (reelRef?.current?.scrollWidth || 0) -
      ((reelRef?.current?.scrollLeft || 0) +
        (reelRef?.current?.clientWidth || 0))
  } else {
    maxScroll = reelRef?.current?.scrollLeft || 0
  }
  return maxScroll < scrollAmount ? maxScroll : scrollAmount
}

export function getNamedScrollPosition(
  reelRef: React.RefObject<HTMLElement>,
  scrollOffset = 0
): NamedScrollPosition {
  // NOTE: 1px check is to account for some scroll containers not being able to
  // fully scroll the entire way to the edge (seen on Brave on MacOS)
  const isStart = (reelRef.current?.scrollLeft ?? 0) + scrollOffset <= 1

  const isEnd =
    (reelRef.current?.scrollLeft ?? 0) +
      (reelRef.current?.clientWidth ?? 0) +
      scrollOffset >=
    // NOTE: 1px check is to account for some scroll containers not being able
    // to fully scroll the entire way to the edge (seen on Brave on MacOS)
    (reelRef.current?.scrollWidth ?? 0) - 1

  if (isStart && isEnd) {
    return 'full'
  }
  if (isStart) {
    return 'start'
  }
  if (isEnd) {
    return 'end'
  }
  return 'middle'
}
